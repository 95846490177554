export default {
  'signin.title': 'Authentication',
  signin: 'Sign in',
  signup: 'Log in',
  'info.signup':
    '  If you do not have an account yet. You can register by clicking on the button below.',
  password_reset: 'Reset your password? ',
  'login.authentication.failed':
    'Please check if your email and password are correct',
  'login.account.disabled': 'Your account is disabled',
  'login.account.not.found': `Sorry, but this account does not exist`,
  'user.email': 'Email',
  'user.password': 'Password',
  'button.login': 'Sign In',
  'user.confirmPassword': 'Confirm Password',
  'pwd.reset': 'Reset Password',
  'error.invalidPassword': 'Invalid password',
  'error.invalidPin': 'Invalid pin',
  click: 'click here',
  'signin.dialog.confirm.login': 'Confirm authentification',
  'signin.code.notValid':
    'The confirmation code entered is incorrect or has expired. ',
  'logincode.confirmed.by.mobile':
    'Your web connection is validated by mobile.',
  'code.validation.by.mobile.error': 'Mobile Validation Failed',
};
