export default {
  'signin.title': 'Connexion',
  signin: 'Se connecter',
  signup: 'Inscription',
  'info.signup':
    "Si vous n'avez pas encore de compte. Vous pouvez vous inscrire en cliquant sur le bouton ci-dessous.",
  password_reset: 'Mot de passe oublié? ',
  'login.authentication.failed':
    'Veuillez vérifier si votre email et votre mot de passe sont corrects',
  'login.account.disabled': 'Votre compte est désactivé',
  'login.account.not.found': `Désolé, mais ce compte n'existe pas`,
  'user.email': 'Email',
  'user.password': 'Mot de passe',
  'user.confirmPassword': 'Confirmer le mot de passe',
  'button.login': "S'authentifié",
  'pwd.reset': 'Changer mot de passe',
  'error.invalidPassword': 'Mot de passe invalide',
  'error.invalidPin': 'Code Pin invalide',
  click: 'cliquez ici',
  'signin.dialog.confirm.login': 'Confirmer mon authentification',
  'signin.code.notValid': 'Le code de confirmation saisi est erroné ou expiré ',
  'logincode.confirmed.by.mobile': 'Votre connexion web est validé par mobile.',
  'code.validation.by.mobile.error':
    'Validation de la connexion web par mobile a échoué',
};
